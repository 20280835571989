import Image from "next/image";
import cn from "clsx";
import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { Text } from "@components/ui";
import trustPilotLogo from "@static/images/icons/trustpilot-logo.png";
import TrustPilotRating from "@components/ui/TrustPilotRating/TrustPilotRating";
import AwardIcon from "@static/images/icons/award.png";
import { useRouter } from "next/router";
import { URL_HOME } from "@middleware/constants";
import style from "./Trustpilot.module.scss";

export type TrustpilotProps = SliceComponentProps<Content.TrustpilotSlice>;

const Trustpilot = ({ slice }: TrustpilotProps): JSX.Element => {
  const { ratingText, verifiedReviews, trustpilotRatingValue, award } =
    slice.primary;
  const ratingValueString = parseFloat(
    trustpilotRatingValue?.toString() ?? "0",
  );
  const router = useRouter();
  const isHomePage = router.pathname === URL_HOME;

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.section}
    >
      <div className={style.wrapper}>
        <div className="lk-wrap">
          <div className={cn(style.flex, { [style.isHomePage]: isHomePage })}>
            <div className={style.numberOne}>
              <Image src={AwardIcon} alt="" width={25} />
              <Text>
                <PrismicRichText field={award} />
              </Text>
            </div>
            <div className={style.trustpilotStars}>
              <div className={style.trustpilotwrap}>
                <Text variant="p" className={style.text}>
                  {ratingText}
                </Text>
                <div>
                  <TrustPilotRating value={ratingValueString} />
                </div>
                <div>
                  <Image src={trustPilotLogo} width={105} alt="" />
                </div>
              </div>
              <Text variant="p" className={style.avisCount}>
                {verifiedReviews}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trustpilot;
